<template>
    <div
            class="h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
            style="height: 100vh; overflow: hidden; color: #101010 !important;">
        <div class="h-100 h-100 flex flex-column">
            <div class="flex h-100">
                <contract-side-menu :active-index="3"></contract-side-menu>
                <div class="right-panel">
                    <MetaRightSideHeader :title-class="'calendar-page-title'">
                        <template #title>
                            时间提醒
                        </template>
                        <template #middle-right>
                            <div class="mode-change-container">
                                <el-radio-group v-model="activeMode">
                                    <el-radio-button label="calendar">日历模式</el-radio-button>
                                    <el-radio-button label="list">列表模式</el-radio-button>
                                </el-radio-group>
                            </div>
                        </template>
                    </MetaRightSideHeader>
                    <div class="content-container shadow bg-white">
                        <div class="content-body custom-scroller" ref="contentBody">
                            <MetaListTable v-if="activeMode === 'list'"
                                           :table-data="tableData"
                                           :table-height="'100%'"
                                           :border="true"
                                           header-row-class-name="calendar-table-header"
                                           class="calendar-table"
                                           :row-class-name="getTableRowClassName"
                                           @cell-click="onTableCellClicked"
                                           :defaultSort="{ prop: 'day'}"
                            >
                                <template v-slot:header>
                                    <div class="action">
                                        <div class="selector-wrap">
                                        </div>
                                        <div class="nowrap ml2 mr2" style="height: 20px; line-height: 20px;display: flex; align-items: center;">
                                            <svg style="fill: currentColor;" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
                                                 viewBox="0 0 32 32" class="fill ln-title mr1 concord-blue">
                                                <path
                                                        d="M24 2.667H8a2.67 2.67 0 00-2.667 2.667v5.333c0 .341.131.683.391.943L12 17.887V28a1.335 1.335 0 002.277.942l5.333-5.333c.251-.249.391-.588.391-.943v-4.781l6.276-6.276c.26-.26.391-.6.391-.943V5.333a2.67 2.67 0 00-2.667-2.667z"></path>
                                            </svg>
                                            <span class="truncate">筛选:</span>
                                        </div>
                                        <div id="attach-select-ember19" class="dib mr4">
                                            <el-select v-model="timeStatus" class="m-2" placeholder="Select" size="mini"
                                                       @change="onTimeFilterChanged">
                                                <el-option
                                                        v-for="item in filterOptions"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>

                                        <el-popover
                                                ref="tags-filter-popover"
                                                placement="bottom"
                                                trigger="click"
                                                width="200">
                                            <template #reference>
                                                <div>
                                                    <div class="flex items-center">
                                                        <div class="pointer mr4">
                                                <span class="nowrap">
                                                  标签
                                                  <i class="material-icons v-mid md-size1">
                                                    arrow_drop_down
                                                  </i>
                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <el-scrollbar :max-height="400">
                                                <el-radio-group style="width: 100%">
                                                    <div class="menu-item" v-for="(tag, index) in normalTagOptions"
                                                         :key="index">
                                                        <el-checkbox v-model="tag.checked"
                                                                     @change="onNormalTagFilterChanged">
                                                            <span class="stage-circle stage-blue"></span>{{tag.name}}
                                                        </el-checkbox>
                                                    </div>
                                                </el-radio-group>
                                            </el-scrollbar>
                                        </el-popover>

                                        <el-popover
                                                ref="tags-filter-popover"
                                                placement="bottom"
                                                trigger="click"
                                                width="200">
                                            <template #reference>
                                                <div>
                                                    <div class="flex items-center">
                                                        <div class="pointer mr2">
                                                <span class="nowrap">
                                                  待办
                                                  <i class="material-icons v-mid md-size1">
                                                    arrow_drop_down
                                                  </i>
                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <el-scrollbar :max-height="400">
                                                <el-radio-group style="width: 100%">
                                                    <div class="menu-item" v-for="(tag, index) in pendingTagOptions"
                                                         :key="index">
                                                        <el-checkbox v-model="tag.checked"
                                                                     @change="onPendingTagFilterChanged">
                                                            <span class="stage-circle stage-blue"></span>{{tag.name}}
                                                        </el-checkbox>
                                                    </div>
                                                </el-radio-group>
                                            </el-scrollbar>
                                        </el-popover>

                                        <div>
                                            <span class="addBtn" @click="contractDateAddClickOnList"> + 添加</span>
                                        </div>

                                    </div>

                                </template>
                                <template v-slot:default>
                                    <el-table-column
                                            min-width="100"
                                            align="left"
                                            label="操作">
                                        <template #header>
<!--                                            <el-checkbox v-model="completeAll" @change="handleCompleteAllChange">已完成-->
<!--                                            </el-checkbox>-->
<!--                                            有待办，无法全选完成-->
                                            <el-checkbox v-model="completeAll" disabled>已完成
                                            </el-checkbox>
                                        </template>

                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'"
                                                 class="contract-date-operate-column-edit">
                                                <div class="btn-status-group" style="display: inline-block" @click.stop>
                                                    <el-checkbox v-if="isContractStartOrEndEvent(contractDateAddOrUpdateData)"
                                                                 label=""
                                                                 disabled/>
                                                    <el-checkbox v-else v-model="contractDateAddOrUpdateData.complete"
                                                                 label=""
                                                                 :checked="contractDateAddOrUpdateData.status === 3"
                                                                 @change="onContractDateStatusChangedForEditMode"/>
                                                </div>
                                            </div>
                                            <div v-else class="contract-date-operate-column-read">
                                                <div class="btn-status-group" @click.stop>
                                                    <el-checkbox v-if="isContractStartOrEndEvent(scope.row)"
                                                                 label=""
                                                                 disabled/>
                                                    <el-checkbox v-else
                                                                 v-model="scope.row.complete"
                                                                 label=""
                                                                 :checked="scope.row.status === 3"
                                                                 @change="onContractDateStatusChanged(scope.row)"/>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            align="left"
                                            label="时间"
                                            min-width="130"
                                            sortable
                                            sort-by="timestamp"
                                            prop="day">
                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'">
                                                <DatetimePicker :contract-date-info="contractDateAddOrUpdateData"></DatetimePicker>
                                            </div>
                                            <div v-else :class="{'expired': hasExpired(scope.row)}">
                                                <span>{{scope.row.day}}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            align="left"
                                            prop="event"
                                            min-width="160"
                                            class-name="table-event-column"
                                            label="事件">
                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'">
                                                <el-input v-model="contractDateAddOrUpdateData.event"
                                                          placeholder="请输入事件"></el-input>
                                            </div>
                                            <div v-else :class="{'expired': hasExpired(scope.row)}">
                                                <span>{{scope.row.event}}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            align="left"
                                            prop="relateMoney"
                                            min-width="180"
                                            class-name="table-relate-money-column"
                                            label="描述">
                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'">
                                                <el-input v-model="contractDateAddOrUpdateData.relateMoney"
                                                          placeholder="请输入描述"></el-input>
                                            </div>
                                            <div v-else :class="{'expired': hasExpired(scope.row)}">
                                                <span v-html="getRelateMoneyForTable(scope.row.relateMoney)"></span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            align="left"
                                            prop="contractTitle"
                                            min-width="160"
                                            label="合同">
                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'">
                                                <div class="contract-title-edit"
                                                     @click.stop="selectContract"
                                                     v-html="getContractTitle()"></div>
                                            </div>
                                            <div v-else class="contract-title-read">{{scope.row.contractTitle}}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="parties"
                                            align="left"
                                            min-width="200"
                                            class-name="table-parties-column"
                                            label="当事人">
                                        <template #header>
                                            <div style="display: flex;">
                                                <div>当事人</div>
                                                <div v-if="!tableShowTag" style="position: absolute; right: 20px; cursor: pointer" @click="tableShowTag = true">
                                                    <svg-icon name="arrow-right" width="16px" height="16px"></svg-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'" class="contract-date-operate-column-read">
                                                <span v-if="!contractDateAddOrUpdateData.parties || contractDateAddOrUpdateData.parties.length === 0 || JSON.parse(contractDateAddOrUpdateData.parties).length === 0"></span>
                                                <div v-else class="otherParties"
                                                     style="padding: 10px 0 10px 20px;height: 46px;display: flex;align-items: center">
                                                    <el-tooltip :hide-after="0" placement="top">
                                                        <template #content>
                                                            <span>{{filterPartiesByTeamOwnKeyWord(JSON.parse(contractDateAddOrUpdateData.parties)).join("、")}}</span>
                                                        </template>
                                                        <el-tag size="small"
                                                                type="info"
                                                                style="color: #101010; font-weight: 400;"
                                                                v-html="getContractPartiesForTable(contractDateAddOrUpdateData.parties)">
                                                        </el-tag>
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                            <div v-else class="contract-date-operate-column-read">
                                                <span v-if="!scope.row.parties || scope.row.parties.length === 0 || JSON.parse(scope.row.parties).length === 0"></span>
                                                <div v-else class="otherParties"
                                                     style="padding: 10px 0 10px 0;height: 46px;display: flex;align-items: center">
                                                    <el-tooltip :hide-after="0" placement="top">
                                                        <template #content>
                                                            <span>{{filterPartiesByTeamOwnKeyWord(JSON.parse(scope.row.parties)).join("、")}}</span>
                                                        </template>
                                                        <el-tag size="small"
                                                                type="info"
                                                                style="color: #101010; font-weight: 400;"
                                                                v-html="getContractPartiesForTable(scope.row.parties)">
                                                        </el-tag>
                                                    </el-tooltip>
                                                </div>

                                                <div v-show="!tableShowTag" class="operate-icon-wrapper">
                                                    <div class="btn-img-wrapper" @click.stop="tableRowEdit(scope)">
                                                        <svg-icon name="contract-date-edit" width="20px" height="20px"></svg-icon>
                                                    </div>
                                                    <div style="margin-left: 15px;" class="btn-img-wrapper"
                                                         @click.stop="handleDelete(scope.row)">
                                                        <svg-icon name="contract-date-delete" width="20px" height="20px"></svg-icon>
                                                    </div>
                                                </div>

                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            v-if="tableShowTag"
                                            prop="tagDTOs"
                                            align="left"
                                            min-width="420"
                                            class-name="table-tags-column"
                                            label="关联待办">
                                        <template #header>
                                            <div style="display: flex;">
                                                <div>关联待办</div>
                                                <div style="position: absolute; right: 20px; cursor: pointer" @click="hideTableTag">
                                                    <svg-icon name="arrow-left" width="16px" height="16px"></svg-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <template #default="scope">
                                            <div v-if="tableRowMode[`rowIndex${scope.row.id}`] === 'edit'"
                                                 class="contract-date-operate-column-edit">
                                                <div class="btn-status-group" :rowid="scope.row.id" style="display: inline-flex;flex:1; align-items: center" @click.stop>
                                                  <!--          tagComposition teleport to                                       -->
                                                </div>
                                                <div style="display: inline-block">
                                                    <!--编辑-->
                                                    <el-button size="mini"
                                                               type="default"
                                                               class="edit-row-cancel-btn"
                                                               @click="editRowCancel">取消</el-button>
                                                    <el-button size="mini"
                                                               type="primary"
                                                               class="edit-row-confirm-btn"
                                                               @click="contractDateSave">保存</el-button>
                                                </div>
                                            </div>
                                            <div v-else class="contract-date-operate-column-read">
                                                <div class="btn-status-group" @click.stop>
<!--                                                    <el-checkbox v-if="isContractStartOrEndEvent(scope.row)"-->
<!--                                                                 label=""-->
<!--                                                                 disabled/>-->
<!--                                                    <el-checkbox v-else-->
<!--                                                                 v-model="scope.row.complete"-->
<!--                                                                 label=""-->
<!--                                                                 :checked="scope.row.status === 3"-->
<!--                                                                 @change="onContractDateStatusChanged(scope.row)"/>-->

                                                    <div class="tags-container" ref="tagContainer">
                                                        <div class="ctc-tag-wrapper"
                                                             :key="tagData.id"
                                                             v-for="tagData in scope.row.tagDTOS">
                                                            <CertificateTag
                                                                    :ref="`certificate-tag-${scope.row.id}`"
                                                                    :tag-data="tagData"
                                                                    :voucherRequired="tagData.voucherRequired"
                                                                    :show-close-btn="false"
                                                                    :preventModifyResolution="true"
                                                                    @closeBtnClicked="() => {}"
                                                                    @preventChangeMode="preventChangeMode = $event"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="operate-icon-wrapper">
                                                    <div class="btn-img-wrapper" @click.stop="tableRowEdit(scope)">
                                                        <svg-icon name="contract-date-edit" width="20px" height="20px"></svg-icon>
                                                    </div>
                                                    <div style="margin-left: 15px;" class="btn-img-wrapper"
                                                         @click.stop="handleDelete(scope.row)">
                                                        <svg-icon name="contract-date-delete" width="20px" height="20px"></svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </template>

                            </MetaListTable>
                            <el-calendar v-else>
                                <template #dateCell="{ data  }">
                                    <div class="date-container">
                                        <div class="date-date">
                                            <div class="calendar-add-event-btn"
                                                 @click="contractDateAddClickOnCalendar(data)">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     class="svg-icon-path-icon fill"
                                                     viewBox="0 0 1024 1024"
                                                     width="16"
                                                     height="16"
                                                     style="color: #6C6C6C">
                                                    <path d="M896 1024 128 1024c-70.4 0-128-57.6-128-128L0 128c0-70.4 57.6-128 128-128l768 0c70.4 0 128 57.6 128 128l0 768C1024 966.4 966.4 1024 896 1024zM128 64C89.6 64 64 89.6 64 128l0 768c0 38.4 25.6 64 64 64l768 0c38.4 0 64-25.6 64-64L960 128c0-38.4-25.6-64-64-64L128 64z" p-id="19293">
                                                    </path>
                                                    <path d="M768 544 256 544C236.8 544 224 531.2 224 512S236.8 480 256 480l512 0c19.2 0 32 12.8 32 32S787.2 544 768 544z" p-id="19294">
                                                    </path>
                                                    <path d="M512 800c-19.2 0-32-12.8-32-32L480 256c0-19.2 12.8-32 32-32S544 236.8 544 256l0 512C544 787.2 531.2 800 512 800z" p-id="19295">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div class="calendar-date-text" v-html="getDateText(data.day)"></div>
                                        </div>
                                        <div class="date-events-container">
                                            <div v-if="eventsData[`${data.day}`] && eventsData[`${data.day}`].length <= 2">
                                                <ContractDateEvent
                                                        v-for="(contractDate, index) in eventsData[`${data.day}`]"
                                                        @click.stop="onContractDateClicked(contractDate, data, $event)"
                                                        :contract-date="contractDate">
                                                </ContractDateEvent>
                                            </div>
                                            <div v-else-if="eventsData[`${data.day}`]">
                                                <ContractDateEvent
                                                        v-for="(contractDate, index) in eventsData[`${data.day}`].slice(0, 2)"
                                                        @click.stop="onContractDateClicked(contractDate, data, $event)"
                                                        :contract-date="contractDate">
                                                </ContractDateEvent>
<!--                                                :width="400"-->
                                                <el-popover
                                                        :ref="'moreEventsPopover' + data.day"
                                                        :placement="getMoreEventsPlacement(data)"
                                                        popper-class="more-events-popover"
                                                        :visible="eventPopoverVisible && currentDay === data.day"
                                                        trigger="manual">
                                                    <template #reference>
                                                        <div class="date-event date-event-more" :data-day="data.day"
                                                             @click="eventPopoverMoreClicked(data.day)">
                                                            <div class="date-event-prefix date-event-more"></div>
                                                            <div class="date-event-content">
                                                                <div class="date-event-text more-event-text">
                                                                    <span>还有</span>
                                                                    <span class="more-event-text-number">{{eventsData[`${data.day}`].length - 2}}</span>
                                                                    <span>个提醒</span></div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <div>
                                                        <div class="events-popover-header">
                                                            <div class="event-month-day"
                                                                 v-html="getMonthAndDayFromData(data)"></div>
                                                            <div @click.stop="moreEventsPopoverClose('moreEventsPopover' + data.day)">
                                                                <button aria-label="close"
                                                                        class="el-dialog__headerbtn"
                                                                        style="position: relative"
                                                                        type="button">
                                                                    <i class="el-icon el-dialog__close">
                                                                        <svg class="icon" width="200" height="200"
                                                                             viewBox="0 0 1024 1024"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill="currentColor"
                                                                                  d="M764.288 214.592L512 466.88 259.712 214.592a31.936 31.936 0 00-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1045.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0045.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 10-45.12-45.184z">
                                                                            </path>
                                                                        </svg>
                                                                    </i>
                                                                </button>
                                                            </div>
                                                        </div>
<!--                                                        <div class="events-popover-day">-->
<!--                                                            {{data.day}}-->
<!--                                                        </div>-->
                                                        <div class="events-popover-contents">
                                                            <ContractDateEvent
                                                                    v-for="(contractDate, index) in eventsData[`${data.day}`].slice(2)"
                                                                    @click.stop="onContractDateClicked(contractDate, data, $event)"
                                                                    :contract-date="contractDate">
                                                            </ContractDateEvent>
                                                        </div>
                                                    </div>
                                                </el-popover>

                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-calendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="meta-dialog" v-show="contractDateEditDialogVisible" @click.stop="">
            <div class="event-edit-dialog-header">
                <div class="tool-btns">
                    <div class="btn-img-wrapper" @click.stop="onContractDateEdit">
                        <i class="el-icon-edit"></i>
                    </div>
                    <div class="btn-img-wrapper" @click.stop="onContractDateDelete">
                        <i class="el-icon-delete"></i>
                    </div>
                    <div class="btn-img-wrapper" @click.stop="contractDateEditDialogVisible = false">
                        <i class="el-icon-close"/>
                    </div>
                </div>
                <div class="summary">
                    <el-checkbox v-if="isContractStartOrEndEvent(currentEditContractDate)"
                                 size="large"
                                 class="summary-checkbox"
                                 disabled/>
                    <el-tooltip v-else
                            effect="dark"
                            placement="top-start"
                    >
                        <template #content>
                            点击设为<strong :style="{color:currentEditContractDate.status == 3 ? `` : `var(--lightBlue)`}">{{+
                            currentEditContractDate.status == 3 ? `待办` : `已办` }}</strong>
                        </template>

                        <el-checkbox :model-value="currentEditContractDate.status == 3"
                                     size="large"
                                     class="summary-checkbox"
                                     @change="onContractDateStatusChangedForDialog"/>
                    </el-tooltip>
                    <span class="summary-title">{{currentEditContractDate.event}}</span>
                </div>
                <div class="eventDetail--entranceContainer"></div>
            </div>
            <div class="event-edit-dialog-content">
                <div class="contract-date-read-dialog-row">
                    <div class="detail-content" @click.stop="onContractTitleClicked" style="word-break: keep-all; text-decoration:underline; cursor: pointer">
                        {{currentEditContractDate.contractTitle}}
                    </div>
                </div>
                <div class="contract-date-read-dialog-row" v-if="currentEditContractDate.relateMoney">
                    <div class="detail-content" v-html="getRelateMoneyForTable(currentEditContractDate.relateMoney)">
                    </div>
                </div>
                <div class="contract-date-read-dialog-row">
                    <div class="detail-content" v-if="currentEditContractDate.parties && currentEditContractDate.parties.length > 0 && JSON.parse(currentEditContractDate.parties).length > 0">当事人：{{JSON.parse(currentEditContractDate.parties).join('、')}}
                    </div>
                    <div class="detail-content" v-else>当事人：无</div>
                </div>
            </div>
        </div>
        <el-dialog
                custom-class="contract-date-edit-dialog"
                v-model="contractDateAddDialogVisible"
                @close="contractDateEditDialogClose"
                @opened="contractDateEditDialogOpened"
                title=""
                width="40%">
<!--            <ContractDateEdit-->
<!--                    ref="contract-date-edit-component"-->
<!--                    :contract-date-info="contractDateAddOrUpdateData"-->
<!--                    :can-change-contract="true">-->
<!--            </ContractDateEdit>-->
<!--            不用组件是因为这个页面有两处搜索合同，采用组件，没办法让两处搜索合同的table显示一个数据-->

            <div v-if="contractDateAddOrUpdateData">
                <div class="contract-date-edit-dialog-row">
                    <div class="detail-icon-title">
                        <span class="detail-title">时间</span>
                        <span class="--mb--rich-text detail-title-required-span" data-boldtype="0">*</span>
                    </div>
                    <div style="width: 100%" v-if="contractDateAddOrUpdateData">
                        <DatetimePicker :contract-date-info="contractDateAddOrUpdateData" :input-inner-padding-left="'15px'"></DatetimePicker>
                    </div>
                </div>

                <div class="contract-date-edit-dialog-row">
                    <div class="detail-icon-title">
                        <span class="detail-title">事件</span>
                        <span class="--mb--rich-text detail-title-required-span" data-boldtype="0">*</span>
                    </div>
                    <el-input v-model="contractDateAddOrUpdateData.event" placeholder="请输入事件" />
                </div>

                <div class="contract-date-edit-dialog-row">
                    <div class="detail-icon-title">
                        <span class="detail-title">描述</span>
                    </div>

                    <el-input v-model="contractDateAddOrUpdateData.relateMoney" placeholder="请输入金额" />
                </div>

                <div v-if="contractDateAddOrUpdateData && tagCompositionShow" class="contract-date-edit-dialog-row conclusive-firstArea conclusive-area">
                    <div class="detail-icon-title" style="margin-top: 13px">
                        <span class="detail-title">关联待办</span>
                    </div>

                    <TagComposition
                        class="excluded"
                        :class="{'disable': contractDateAddOrUpdateData.isPeriodTime}"
                        ref="tagComposition"
                        style="flex:1;"
                        :selectable-tags-data="allPendingTags"
                        :selected-tags-data="contractDateAddOrUpdateData.tagDTOS"
                        :create-new-tag-data-default-type="TagType.NEED_RESOLVE"
                        :list-placeholder="'请选择或创建一个待办标签'"
                        :input-classes="tagCompositionInputClasses"
                        :placeholder="tagCompositionInputPlaceholder"
                        :list-teleport-to="'.contract-date-edit-dialog'"
                        :list-z-index="1"
                        :list-width="'auto'"
                        :list-max-height="200"
                        :prevent-wrapper-modify-resolution="true"
                        :singleton-mode="true"
                        :external-requests="{
                                addTag:(name, type) => contractDateAddTag(name, type),
                                removeTagRelation:(tagId) => contractDateDeleteTag(tagId),
                                completeTag:(tagId,formData) => contractDateCompleteTag(tagId,formData),
                                cancelTagCompleteStatus: (tagId) => contractDateCancelTagCompleteStatus(tagId)
                              }"
                        @modeChanged="handleTagCompositionModeChanged"
                        @tagResolved="handleTagCompositionTagResolved"
                        @containerTagDataAppended="()=>{}" />

                    <span v-if="!contractDateAddOrUpdateData.isPeriodTime"
                          class="conclusive-firstArea-icon"
                          :class="{show:!tagEditMode}" style="margin-top: 9px;"
                          @click="clickTagsEdit">
                              <img
                                      style="width: 20px;height:20px;"
                                      src="../../assets/images/editor.png"
                              />
                            </span>
                </div>

                <div class="contract-date-edit-dialog-row">
                    <div class="detail-icon-title">
                        <span class="detail-title">合同</span>
                        <span class="--mb--rich-text detail-title-required-span" data-boldtype="0">*</span>
                    </div>
                    <div class="contract-date-edit-dialog-row-content contract-title-edit-for-dialog"
                         @click="selectContract"
                         v-html="getContractTitle()"></div>
                </div>


                <div class="contract-date-edit-dialog-row">
                    <div class="detail-icon-title">
                        <span class="detail-title">当事人</span>
                    </div>
                    <div class="contract-date-edit-dialog-row-content" v-if="contractDateAddOrUpdateData.parties && contractDateAddOrUpdateData.parties.length > 0 && JSON.parse(contractDateAddOrUpdateData.parties).length > 0">{{JSON.parse(contractDateAddOrUpdateData.parties).join('、')}}</div>
                    <div class="contract-date-edit-dialog-row-content" v-else>无</div>
                </div>
            </div>


            <template #footer>
              <span class="dialog-footer">
                <el-button @click="contractDateAddCancel">取消</el-button>
                <el-button type="primary" @click="contractDateSave">确定</el-button>
              </span>
            </template>
        </el-dialog>


        <el-dialog
                v-model="selectContractDialogVisible"
                title=""
                width="620px"
                @closed="selectContractDialogClosed">
            <ContractSelector
                    ref="contract-selector"
                    :init-recently-modified="true"
                    :status-list="6"
                    :status-visible="true">
            </ContractSelector>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="selectContractCancel">取消</el-button>
                <el-button type="primary" @click="selectContractConfirm">确定</el-button>
              </span>
            </template>
        </el-dialog>
        <div v-if="contractDateAddOrUpdateData && tableRowMode[`rowIndex${contractDateAddOrUpdateData.id}`] === 'edit'" style="display:none;">
          <teleport :to="tagCompositionTeleportTo || '#app'">
            <TagComposition
                ref="table-edit-tagComposition"
                class="contract-deadline-tc"
                style="flex:1;"
                :selectable-tags-data="allPendingTags"
                :selected-tags-data="contractDateAddOrUpdateData && contractDateAddOrUpdateData.tagDTOS ? contractDateAddOrUpdateData.tagDTOS : []"
                :create-new-tag-data-default-type="TagType.NEED_RESOLVE"
                :list-placeholder="'请选择或创建一个待办标签'"
                :placeholder="'请关联待办'"
                :list-z-index="2000"
                :list-width="180"
                :list-max-height="200"
                :disable-auto-change-mode="true"
                :prevent-wrapper-modify-resolution="true"
                :singleton-mode="true"
                :external-requests="{
                  addTag:(name, type) => contractDateAddTag(name, type),
                  removeTagRelation:(tagId) => contractDateDeleteTag(tagId),
                  completeTag:(tagId,formData) => contractDateCompleteTag(tagId,formData),
                  cancelTagCompleteStatus: (tagId) => contractDateCancelTagCompleteStatus(tagId)
                }"
                @modeChanged="handleTagCompositionModeChanged"
                @tagResolved="handleTagCompositionTagResolved"
                @containerTagDataAppended="()=>{}" />
          </teleport>
        </div>

        <div v-if="changeTagStatusData && changeTagStatusData.tagDTOS.length > 0">
            <CertificateTag
                    ref="certificate-tag-change-status"
                    :tag-data="changeTagStatusData.tagDTOS[0]"
                    :voucherRequired="changeTagStatusData.tagDTOS[0].voucherRequired"
                    :show-close-btn="false"
                    :preventModifyResolution="false"
                    @closeBtnClicked="() => {}"
                    @preventChangeMode="preventChangeMode = $event"
                    :external-requests="{
                        completeTag:(tagId,formData) => contractDateCompleteTagForCheckbox(tagId,formData),
                        cancelTagCompleteStatus: (tagId) => contractDateCancelTagCompleteStatusForCheckbox(tagId)
                    }"
            />
        </div>

    </div>
</template>

<script>
    import {
        getContractDates,
        deleteContractDate,
        setContractDateStatus,
        getTags,
        addContractDate,
        updateContractDate,
        batchSetContractDateStatus,
        addTag,
        addContractDateTag,
        deleteContractDateTag,
        completeContractDateTag,
        cancelCompleteContractDateTag,
    } from "../../api/api";
    import ContractSideMenu from "../../components/ContractSideMenu";
    import {BriefEmitter, colorLog, debounce, deepClone, MetaMessage, transformTimeInfo} from "../../util";
    import {ElMessageBox, ElMessage} from 'element-plus'
    import MetaListTable from "../../components/list/MetaListTable";
    import MetaRightSideHeader from "../../components/universalUI/MetaRightSideHeader";
    import ContractDateEvent from "../../components/contract-date/ContractDateEvent";
    import {globalTagClick} from "../../assets/js/tag/tag";
    import {META_LIST_COLUMN_TYPES} from "../../components/list/MetaListEnumberable";
    import DatetimePicker from "../../components/date-picker/datetime-picker";
    import {ContractDateStatus, ContractDateFilter} from "../../constant/contract"
    import ContractSelector from "../../components/contract/ContractSelector";
    import TagComposition from "../../components/tag/TagComposition";
    import CertificateTag from "../../components/tag/CertificateTag";
    import {nextTick, provide} from "vue";
    import {eventsName, TagCompositionMode, TagType, TagStatus} from "../../components/tag/configure";
    import SvgIcon from "../../components/SvgIcon/svgIcon";

    export default {
        name: "ContractDeadline",
        components: {
            DatetimePicker,
            MetaRightSideHeader,
            MetaListTable,
            ContractSideMenu,
            ContractDateEvent,
            ContractSelector,
            TagComposition,
            CertificateTag,
            SvgIcon
        },
        data() {
            return {
                META_LIST_COLUMN_TYPES,
                colorLog,
                currentContractDateInfo: null,
                filterOptions: [
                    {
                        "label": "待完成",
                        "value": 4
                    },
                    {
                        "label": "全部",
                        "value": 0
                    },
                    {
                        "label": "未到期",
                        "value": 1
                    },
                    {
                        "label": "已超期",
                        "value": 2
                    },
                    {
                        "label": "已完成",
                        "value": 8
                    },
                ],
                timeStatus: 4,
                filterNormalTagIds: [],
                filterPendingTagIds: [],
                metaMessage: new MetaMessage(),
                currentIndex: 1,
                size: 20,
                totalCount: 0,
                totalPage: 0,
                currentCount: 0,
                tableData: [],
                eventsData: {},
                activeMode: sessionStorage.getItem('contractDateActiveMode') || 'calendar', // list, calendar
                currentContractDate: null,
                currentDayEvents: [],
                calendarCurrentData: {},
                currentEditContractDate: {},
                eventPopoverVisible: false,
                contractDateEditDialogVisible: false,
                currentDay: null,//用来判断是哪个日期的popover
                debounceTagClickFun: debounce((val) => {
                    globalTagClick('contract-deadline-time-sift', {text: val});
                }, 500),
                contractDateAddOrUpdateData: null,
                contractDateAddOrUpdateDataBackup: null,
                contractDateAddDialogVisible: false,
                tagCompositionShow: false,
                tagCompositionInputPlaceholder: '请关联待办',
                tagCompositionInputClasses: 'calendar-tag-input',
                completeAll: false,
                tableAdd: false,
                tableRowAddId: 0,
                tableRowMode: {},
                selectContractDialogVisible: false,
                searchContractKeyWords: '',
                contracts: [],
                searchTime: null,
                selectedContract: null,
                allTags:[],
                allPendingTags: [],
                normalTagOptions:[],
                pendingTagOptions:[],
                tagEditMode: false,
                tagCompositionTeleportTo:'',
                tableShowTag: false,
                TagType,
                changeTagStatusData: null,
            }
        },
        watch: {
            activeMode: {
                immediate: true,
                handler(newVal) {
                    sessionStorage.setItem('contractDateActiveMode', newVal);
                    globalTagClick('contract-deadline-switch-mode-to-' + newVal);
                    if (newVal === 'calendar') {
                        this.editRowCancel();
                        this.$nextTick(() => {
                            let thead = this.$refs.contentBody.querySelector('.el-calendar-table thead');
                            thead.innerHTML = `
                  <th>周日</th>
                  <th>周一</th>
                  <th>周二</th>
                  <th>周三</th>
                  <th>周四</th>
                  <th>周五</th>
                  <th>周六</th>
                `
                        });
                        this.changeCalendarButtons();
                    }
                }
            },

        },
        mounted() {
            this.getAllTags();
            this.initDates();
            document.addEventListener("click", this.bodyCloseMenus);
            this.changeCalendarButtons();
        },
        unmounted() {
            this.offMoreEventsPopoverGlobalListener();
            document.removeEventListener("click", this.bodyCloseMenus);
        },
        methods: {
            globalTagClick,
            tableEventChange(scope) {
                updateContractDate(scope.id, scope).then(res => {
                    this.metaMessage['success']('更新事件成功');
                }).catch(err => {
                    this.metaMessage['error']('更新事件失败');
                    console.red('updateContractDate  err:', err);
                })
            },
            tableRelateMoneyChange(scope) {
                updateContractDate(scope.id, scope).then(res => {
                    this.metaMessage['success']('更新涉及成功');
                }).catch(err => {
                    this.metaMessage['error']('更新涉及失败');
                    console.red('updateContractDate  err:', err);
                })
            },
            changeCalendarButtons: function () {
                this.$nextTick(() => {
                    const buttons = document.querySelectorAll(".el-calendar__button-group button");
                    buttons.forEach(item => {
                        item.style.border = "none";
                    })

                    buttons[1].style.height = "36px";
                    buttons[1].style.color = "#000000";
                    buttons[1].lastChild.innerHTML = "今日";
                    buttons[0].innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon-path-icon\" viewBox=\"0 0 1024 1024\" width=\"20\" height=\"20\"><path d=\"M319.64 512.016l336.016-336.008 45.248 45.248L364.896 557.28z\" p-id=\"4080\"></path><path d=\"M365.216 466.464l339.976 339.968-45.256 45.256-339.976-339.976z\" p-id=\"4081\"></path></svg>";
                    buttons[2].innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon-path-icon\" viewBox=\"0 0 1024 1024\" width=\"20\" height=\"20\"><path d=\"M658.56 557.392L322.536 221.384l45.248-45.256 336.016 336.008z\" p-id=\"4231\"></path><path d=\"M704.088 512.2L364.12 852.16l-45.256-45.248 339.976-339.976z\" p-id=\"4232\"></path></svg>";
                })
            },
            bodyCloseMenus: function () {
                console.log('bodyCloseMenus === ');
                if (this.contractDateEditDialogVisible === true) {
                    this.contractDateEditDialogVisible = false;
                }
            },
            initDates: function () {
                const params = {
                    timeStatus: this.timeStatus,
                    normalTagIds: this.filterNormalTagIds,
                    pendingTagIds: this.filterPendingTagIds
                }
                getContractDates(params).then(res => {
                    if (res.data.code === 0) {
                        this.tableData = [];
                        this.$nextTick(() => {
                            this.tableData = res.data.data.contractDateDTOS;
                            //去除'不明' 和 '有限公司'
                            this.tableData.forEach(item => {
                                if (item.parties) {
                                    item.parties = item.parties.replace(/(有限公司|不明)/g, '');
                                    let arr = JSON.parse(item.parties);
                                    arr = arr.filter(item => item.length > 0);
                                    // item.parties = arr.join('、');
                                    item.parties = JSON.stringify(arr);
                                } else {
                                    item.parties = '[]'
                                }
                            })
                            this.generateCalendarData();
                            this.calculateCompleteAll();
                            if (this.timeStatus === ContractDateFilter.ALL) {
                                this.initTags(this.allTags);
                            } else {
                                this.initTags(res.data.data.tagDTOS);
                            }
                        })
                    } else {
                        this.metaMessage['error']('查询数据失败');
                    }
                }).catch(err => {
                    console.error('getContractDates   err ==== ', err);
                    this.metaMessage['error']('查询数据失败');
                });
            },
            generateCalendarData: function () {
                this.eventsData = {};
                for (const event of this.tableData) {
                    if (!this.eventsData[`${event.day}`]) this.eventsData[`${event.day}`] = [];
                    this.eventsData[`${event.day}`].push(event);
                }
                this.sortEventsData();
            },
            sortEventsData: function () {
                // eventsData排序，已完成的放在未完成的后面
                for (const dayKey of Object.keys(this.eventsData)) {
                    const dayEvents = this.eventsData[dayKey];
                    const newDayEvents = [];
                    for (let i = 0; i < dayEvents.length; i++) {
                        if (dayEvents[i].status !== 3) {
                            newDayEvents.push(dayEvents[i]);
                        }
                    }
                    for (let i = 0; i < dayEvents.length; i++) {
                        if (dayEvents[i].status === 3) {
                            newDayEvents.push(dayEvents[i]);
                        }
                    }
                    this.eventsData[dayKey] = newDayEvents;
                }
            },
            onTableCellClicked: function (row, column, cell, event) {
                if (column.property === "contractTitle" && row.id > 0) {
                    globalTagClick('contract-deadline-jump-to-contract-view', {'contract_id': row.contractId})
                    this.openNewPage(
                        'contractView',
                        {
                            id: row.contractId,
                            highlightStart: row.startIndex,
                            highlightEnd: row.endIndex,
                        })
                }
            },
            getTableRowClassName: function (row, rowIndex) {
                if (row.row.id < 0 || (this.contractDateAddOrUpdateData && row.row.id === this.contractDateAddOrUpdateData.id)) {
                    return 'row-edit';
                } else {
                    return 'row-read';
                }
            },
            openNewPage: function (name, query) {
                let newPage = this.$router.resolve({
                    name: name,
                    query: query
                })
                window.open(newPage.href)
            },
            gotoPage: function (name) {
                this.$router.push({name: name})
            },
            getDateText: function (day) {
                const dates = day.split('-');
                // return dates[dates.length - 1] + '日';
                return parseInt(dates[dates.length - 1]);
            },
            contractDateAddClickOnCalendar: function (data) {
                console.log('contractDateAddClickOnCalendar   data === ', data);
                this.initContractDateAddData(data.date.getTime());
                this.contractDateAddDialogVisible = true;
            },
            contractDateAddClickOnList: function () {
                if (this.tableAdd) return;
                this.editRowCancel();
                this.initContractDateAddData(null);
                this.tableData.unshift(this.contractDateAddOrUpdateDataBackup);
                this.tableRowMode[`rowIndex${this.contractDateAddOrUpdateData.id}`] = 'edit';
                this.tableAdd = true;
                this.tableShowTag = true;

                this.$nextTick(() => {
                    this.tagCompositionTeleportTo = `.btn-status-group[rowid="${this.contractDateAddOrUpdateData.id}"]`
                    const tagComposition = this.$refs['table-edit-tagComposition'];
                    tagComposition.api.resetTagsPosition();
                    tagComposition.api.changeMode(TagCompositionMode.INTACT_EDIT);
                    this.$nextTick(() => {
                        setTimeout(() => {
                            tagComposition.api.closePopper();
                            tagComposition.nameInput.blur();
                        }, 0)
                    })
                })
            },
            initContractDateAddData: function (timestamp) {
                this.contractDateAddOrUpdateDataBackup = {
                    id: --this.tableRowAddId,
                    timestamp: timestamp,
                    contractId: null,
                    contractTitle: null,
                    parties: "[]",
                    event: '',
                    relateMoney: '',
                    isPeriodTime: false,
                    periodTimeNumber: 1,
                    periodTimeUnit: 2,
                    tagDTOS: []
                };
                this.contractDateAddOrUpdateData = JSON.parse(JSON.stringify(this.contractDateAddOrUpdateDataBackup));
            },
            contractDateAddCancel: function () {
                this.contractDateAddDialogVisible = false;
                this.tableRowMode = {};
                this.contractDateAddOrUpdateDataBackup = null;
                this.contractDateAddOrUpdateData = null;
            },
            contractDateSave: function () {
                console.log('contractDateSave   contractDateAddOrUpdateData:', this.contractDateAddOrUpdateData)
                // check
                if (!this.checkContractDate(this.contractDateAddOrUpdateData)) {
                    return;
                }

                if (this.contractDateAddOrUpdateData.id && this.contractDateAddOrUpdateData.id > 0) {
                    // update
                    updateContractDate(this.contractDateAddOrUpdateData.id, this.contractDateAddOrUpdateData).then(res => {
                        console.log('updateContractDate  res:', res);
                        if (res.data.code === 0) {
                            this.contractDateAddDialogVisible = false;
                            if (this.contractDateNeedHide(this.contractDateAddOrUpdateData)) {
                                this.removeContractDateAndFresh(this.contractDateAddOrUpdateData);
                            } else {
                                for (const key of Object.keys(this.contractDateAddOrUpdateData)) {
                                    this.contractDateAddOrUpdateDataBackup[key] = this.contractDateAddOrUpdateData[key];
                                }
                                this.generateCalendarData();
                                this.calculateCompleteAll();
                                this.tableRowMode = {};
                                this.contractDateAddOrUpdateDataBackup = null;
                                this.contractDateAddOrUpdateData = null;
                                this.contractDateEditDialogVisible = false;
                            }
                        } else {
                            this.metaMessage['error']('更新事件失败:' + res.data.msg);
                        }
                    }).catch(err => {
                        this.metaMessage['error']('更新事件失败');
                        console.log('updateContractDate  err:', err);
                    })
                } else {
                    // add
                    this.contractDateAddOrUpdateData.id = null;
                    addContractDate(this.contractDateAddOrUpdateData.contractId, this.contractDateAddOrUpdateData).then(res => {
                        console.log('addContractDate  res:', res);
                        if (res.data.code === 0) {
                            this.contractDateAddDialogVisible = false;
                            const tableDataCopy = JSON.parse(JSON.stringify(this.tableData));
                            this.tableData = [];
                            this.$nextTick(() => {
                                // 如果是列表添加，需要删掉第一行的临时元素
                                if (this.tableAdd) {
                                    tableDataCopy.shift();
                                    this.tableRowMode = {};
                                    this.tableAdd = false;
                                }
                                for (const newContractDateInfo of res.data.data) {
                                    tableDataCopy.unshift(newContractDateInfo);
                                    if (this.eventsData[newContractDateInfo.day]) {
                                        this.eventsData[newContractDateInfo.day].push(newContractDateInfo);
                                    } else {
                                        this.eventsData[newContractDateInfo.day] = [newContractDateInfo];
                                    }
                                }
                                this.sortEventsData();
                                this.tableData = tableDataCopy;
                                this.contractDateAddOrUpdateDataBackup = null;
                                this.contractDateAddOrUpdateData = null;
                                this.calculateCompleteAll();
                            })
                        } else {
                            this.metaMessage['error']('添加事件失败:' + res.data.msg);
                        }
                    }).catch(err => {
                        this.metaMessage['error']('添加事件失败');
                        console.log('addContractDate  err:', err);
                    })
                }
            },
            handleEdit: function (data) {
                console.log('handleEdit === data === ', data);
                this.contractDateAddOrUpdateDataBackup = data;
                this.contractDateAddOrUpdateData = JSON.parse(JSON.stringify(data));
                this.contractDateAddDialogVisible = true;
            },
            tableRowEdit: function (scope) {
                this.editRowCancel();
                this.tableRowMode[`rowIndex${scope.row.id}`] = 'edit';
                this.contractDateAddOrUpdateDataBackup = scope.row;
                this.contractDateAddOrUpdateData = JSON.parse(JSON.stringify(scope.row));
                this.tableShowTag = true;

                // console.log('tableRowEdit  this.contractDateAddOrUpdateData  11 === ', deepClone(this.contractDateAddOrUpdateData))
                this.$nextTick(() => {
                  this.tagCompositionTeleportTo = `.btn-status-group[rowid="${scope.row.id}"]`
                  const tagComposition = this.$refs['table-edit-tagComposition'];
                  tagComposition.api.resetTagsPosition();
                  tagComposition.api.changeMode(TagCompositionMode.INTACT_EDIT);
                    // console.log('tableRowEdit  this.contractDateAddOrUpdateData 333 === ', deepClone(this.contractDateAddOrUpdateData))
                    this.$nextTick(() => {
                        setTimeout(() => {
                            tagComposition.api.closePopper();
                            tagComposition.nameInput.blur();
                            // console.log('tableRowEdit  this.contractDateAddOrUpdateData 22 === ', deepClone(this.contractDateAddOrUpdateData))
                        }, 0)
                    })
                })
            },
            hideTableTag: function () {
                this.tableShowTag = false;
                this.editRowCancel();
            },
            editRowCancel: function () {
                this.tableAdd = false;
                this.tableRowMode = {};
                this.tagCompositionTeleportTo = '';
                this.$refs['table-edit-tagComposition']?.api.changeMode(TagCompositionMode.DISPLAY);

                if (this.contractDateAddOrUpdateData
                    && this.contractDateAddOrUpdateData.id < 0
                    && this.tableData.length > 0
                    && this.contractDateAddOrUpdateData.id === this.tableData[0].id) {
                    this.tableData.shift();
                }
                this.contractDateAddOrUpdateDataBackup = null;
                this.contractDateAddOrUpdateData = null;
            },
            checkContractDate: function (contractDate) {
                if (!contractDate.timestamp) {
                    this.metaMessage['error']('请设置日期');
                    return false;
                }
                if (!contractDate.contractId) {
                    this.metaMessage['error']('请选择合同');
                    return false;
                }

                if (!contractDate.event) {
                    this.metaMessage['error']('请输入事件名称');
                    return false;
                }

                contractDate['tagIds'] = contractDate.tagDTOS.map(tag => tag.id);

                return true;
            },
            handleDelete: function (row) {
                this.currentContractDate = row;
                console.log('handleDelete  row === ', row);
                const alertInfo = '删除后团队其他成员也无法看见此日历，确定删除？';
                ElMessageBox.confirm(
                    alertInfo,
                    '提示',
                    {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'info',
                        dangerouslyUseHTMLString: true,
                    },
                )
                    .then(() => {
                        globalTagClick('contract-deadline-delete-contract-agenda');
                        deleteContractDate(this.currentContractDate.id).then(res => {
                            this.contractDateEditDialogVisible = false
                            this.removeContractDateAndFresh(this.currentContractDate);
                        }).catch(err => {
                            console.error('deleteContractDate  err === ', err);
                            this.metaMessage['error']('删除失败');
                        })
                    })
                    .catch(() => {
                    })
            },
            removeContractDateAndFresh: function (contractDate) {
                // const contractDate = JSON.parse(JSON.stringify(contractDateParam));
                const index = this.findContractDateIndex(contractDate);
                const tableDataCopy = JSON.parse(JSON.stringify(this.tableData));
                this.tableData = [];
                tableDataCopy.splice(index, 1);
                this.$nextTick(() => {
                    this.tableData = tableDataCopy;
                    this.generateCalendarData();
                    this.calculateCompleteAll();
                    this.tableRowMode = {};
                    this.contractDateAddOrUpdateDataBackup = null;
                    this.contractDateAddOrUpdateData = null;
                    this.contractDateEditDialogVisible = false;
                })
            },
            calculateCompleteAll: function () {
                if (this.tableData.length === 0) {
                    this.completeAll = false;
                    return;
                }
                let completeAll = true;
                for (const contractDate of this.tableData) {
                    if (contractDate.status !== ContractDateStatus.PROCESSED) {
                        completeAll = false;
                        break;
                    }
                }
                this.completeAll = completeAll;
            },
            onContractDateClicked: function (contractDate, date, e) {
                this.calendarCurrentData = date;
                this.currentEditContractDate = contractDate;
                this.contractDateEditDialogVisible = true;

                let x = e.clientX;
                let y = e.clientY;

                const diaolg = document.querySelector('.meta-dialog');
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;
                const dialogWidth = windowWidth * 0.275;
                const dialogHeight = 260;

                if (date.date.getDay() <= 3) {
                    // 右边显示
                    x += 30;
                } else {
                    // 左边显示
                    x -= 30 + dialogWidth;
                }
                if (y + dialogHeight > windowHeight - 20) {
                    y = windowHeight - 20 - dialogHeight;
                }
                diaolg.style.left = x + "px";
                diaolg.style.top = y + "px";
            },
            findContractDateIndex: function (contractDate) {
                let index = -1;
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].id === contractDate.id) {
                        index = i;
                        break;
                    }
                }

                return index;
            },
            onContractDateEdit: function () {
                this.handleEdit(this.currentEditContractDate);
            },
            onContractDateDelete: function () {
                this.handleDelete(this.currentEditContractDate);
            },
            moreEventsPopoverClose: function (name) {
                // this.$refs[name].hide();
                this.eventPopoverVisible = false;
                this.offMoreEventsPopoverGlobalListener();
            },
            eventPopoverMoreClicked: function (day) {
                colorLog.red('eventPopoverMoreClicked  this.eventPopoverVisible === ', this.eventPopoverVisible);
                if (this.eventPopoverVisible) {
                    return;
                }
                this.eventPopoverVisible = !this.eventPopoverVisible;
                this.contractDateEditDialogVisible = false;
                if (this.eventPopoverVisible) {
                    this.currentDay = day;
                    this.onMoreEventsPopoverGlobalListener();
                }
            },
            closeMoreEventsPopoverGlobalCallback(e) {
                let moreEventsPopover = e.target.closest('.more-events-popover');
                if (!moreEventsPopover && !this.contractDateEditDialogVisible) {
                    this.eventPopoverVisible = false;
                    this.offMoreEventsPopoverGlobalListener();
                    let target = e.target.closest('.date-event-more');
                    colorLog.blue('closeMoreEventsPopoverGlobalCallback')

                    if (target && target.dataset.day == this.currentDay) {
                        e.stopPropagation();
                    }
                }
            },
            onMoreEventsPopoverGlobalListener() {
                document.body.addEventListener('click', this.closeMoreEventsPopoverGlobalCallback, {capture: true})
            },
            offMoreEventsPopoverGlobalListener() {
                document.body.removeEventListener('click', this.closeMoreEventsPopoverGlobalCallback, {capture: true});
            },
            getMoreEventsPlacement: function (data) {
                // console.log('getMoreEventsPlacement  data === ', data);
                // const week = "周" + "日一二三四五六".charAt(data.date.getDay())
                // console.log('getMoreEventsPlacement  data.date.getDay() === ', data.date.getDay());
                // console.log('getMoreEventsPlacement  week === ', week);
                if (data.date.getDay() <= 3) {
                    return 'right';
                } else {
                    return 'left';
                }
            },
            getWeekFromData: function (data) {
                const week = "星期" + "日一二三四五六".charAt(data.date.getDay());
                return week;
            },
            getMonthAndDayFromData: function (data) {
                const day = data.day;
                const array = day.split("-");
                return parseInt(array[1]) + "月" + parseInt(array[2]) + "日";
            },
            getEventText: function (contractDate) {
                return `${contractDate.event}|${contractDate.contractTitle}`;
            },
            onContractTitleClicked: function () {
                let newPage = this.$router.resolve({
                    name: 'contractView',
                    query: {
                        'id': this.currentEditContractDate.contractId,
                        'highlightStart': this.currentEditContractDate.startIndex,
                        'highlightEnd': this.currentEditContractDate.endIndex,
                    }
                })
                window.open(newPage.href)
            },
            getEditContractDateTime: function () {
                const date = new Date(this.currentEditContractDate.timestamp);
                return `当天${date.getHours()}:${date.getMinutes()}`;
            },
            onCurrentEditContractDateStatusChanged: function (value) {
                console.log('onCurrentEditContractDateStatusChanged  value === ', value);
                const postData = {
                    "status": value,
                }
                setContractDateStatus(this.currentEditContractDate.id, postData).then(res => {
                    console.log('setContractDateStatus  res === ', res);
                }).catch(err => {
                    console.error('setContractDateStatus  err === ', err);
                    this.metaMessage['error']('修改状态失败');
                })
            },
            handleCompleteAllChange: function (value) {
                console.log('handleCompleteAllChange  value:', value);
                const status = value ? ContractDateStatus.PROCESSED : ContractDateStatus.PENDING;
                const ids = [];
                for (const contractDate of this.tableData) {
                    ids.push(contractDate.id);
                }
                const postData = {
                    "status": status,
                    "contractDateIds": ids
                }
                batchSetContractDateStatus(postData).then(res => {
                    // this.updateStatus(status);
                }).catch(err => {
                    console.error('batchSetContractDateStatus  err === ', err);
                    this.metaMessage['error']('修改状态失败');
                })
            },
            updateStatus: function (status) {
                if ((status === ContractDateStatus.PROCESSED && (this.timeStatus === ContractDateFilter.EXPIRED || this.timeStatus === ContractDateFilter.PENDING))
                    || (status === ContractDateStatus.PENDING && this.timeStatus === ContractDateFilter.PROCESSED)) {
                    // clear all
                    this.tableData = [];
                    this.eventsData = {};
                    return;
                }

                for (const contractDate of this.tableData) {
                    contractDate.status = status;
                    contractDate.complete = status === ContractDateStatus.PROCESSED;
                }

                for (const key of Object.keys(this.eventsData)) {
                    for (const contractDate of this.eventsData[key]) {
                        contractDate.status = status;
                        contractDate.complete = status === ContractDateStatus.PROCESSED;
                    }
                }
            },
            onContractDateStatusChangedForEditMode: function (value) {
                if (value) {
                    this.contractDateAddOrUpdateData.status = ContractDateStatus.PROCESSED;
                } else {
                    this.contractDateAddOrUpdateData.status = ContractDateStatus.PENDING;
                }
            },
            onContractDateStatusChangedForDialog: function (value) {
                // this.currentEditContractDate.complete = value;
                this.onContractDateStatusChanged(this.currentEditContractDate, true);
            },
            onContractDateStatusChanged: function (contractDate, isDialog = false) {
                // console.log('onContractDateStatusChanged  contractDate === ', deepClone(contractDate));
                if (contractDate.tagDTOS.length > 0) {
                    // 关联了待办，则时间提醒的“完成”和“取消完成”的逻辑同待办的“完成”和“取消完成”
                    // 先将checkbox的状态改回去
                    if (!isDialog) {
                        if (contractDate.complete) {
                            contractDate.complete = false;
                            contractDate.status = ContractDateStatus.PENDING;
                            contractDate.tagDTOS[0].status = TagStatus.pending;
                        } else {
                            contractDate.complete = true;
                            contractDate.status = ContractDateStatus.PROCESSED;
                            contractDate.tagDTOS[0].status = TagStatus.complete;
                        }
                    }
                    // 再触发待办标签的“完成”或“取消完成时间”
                    this.changeContractDateTagStatus(contractDate);
                    return false;
                }
                contractDate.status = contractDate.complete ? ContractDateStatus.PROCESSED : ContractDateStatus.PENDING;
                globalTagClick('contract-deadline-date-status-change', {
                    'contract_id': contractDate.contractId,
                    'status': contractDate.status
                });
                const postData = {
                    "status": contractDate.status,
                }
                setContractDateStatus(contractDate.id, postData).then(res => {
                    // if (this.contractDateNeedHide(contractDate)) {
                    //     this.removeContractDateAndFresh(contractDate);
                    // } else {
                    //     this.sortEventsData();
                    //     this.calculateCompleteAll();
                    // }
                    this.sortEventsData();
                    this.calculateCompleteAll();
                }).catch(err => {
                    console.error('setContractDateStatus  err === ', err);
                    this.metaMessage['error']('修改状态失败');
                })
            },
            changeContractDateTagStatus: function (contractDate) {
                this.changeTagStatusData = contractDate;
                // console.log('changeContractDateTagStatus  this.changeTagStatusData === ', deepClone(this.changeTagStatusData));
                this.$nextTick(() => {
                    this.$refs['certificate-tag-change-status'].handleTagClicked();
                })
            },
            contractDateNeedHide: function (contractDate) {
                if (this.timeStatus === ContractDateFilter.NOT_EXPIRED) {
                    // 未到期
                    return new Date().getTime() > contractDate.timestamp;
                } else if (this.timeStatus === ContractDateFilter.PENDING) {
                    // 待完成
                    return contractDate.status === ContractDateStatus.PROCESSED;
                } else if (this.timeStatus === ContractDateFilter.PROCESSED) {
                    // 已完成
                    return contractDate.status < ContractDateStatus.PROCESSED;
                } else if (this.timeStatus === ContractDateFilter.EXPIRED) {
                    // 已超期
                    return !this.hasExpired(contractDate);
                }

                return false;
            },
            onTimeFilterChanged: function (newValue) {
                this.debounceTagClickFun(newValue);
                this.initDates();
            },
            onNormalTagFilterChanged: function (value) {
                this.filterNormalTagIds = [];
                for (const contractTag of this.normalTagOptions) {
                    if (contractTag.checked === true) this.filterNormalTagIds.push(contractTag.id);
                }
                globalTagClick('contract-deadline-status-sift', {
                    normalTagIds: this.filterNormalTagIds
                });
                this.initDates();
            },
            onPendingTagFilterChanged: function (value) {
                this.filterPendingTagIds = [];
                for (const contractTag of this.pendingTagOptions) {
                    if (contractTag.checked === true) this.filterPendingTagIds.push(contractTag.id);
                }
                globalTagClick('contract-deadline-status-sift', {
                    pendingTagIds: this.filterPendingTagIds
                });
                this.initDates();
            },
            selectContract: function () {
                this.selectContractDialogVisible = true;
            },
            getContractTitle: function () {
                return this.contractDateAddOrUpdateData.contractTitle || "请选择合同";
            },
            hasExpired: function (contractDate) {
                if (this.isContractStartOrEndEvent(contractDate)) return false;
                return new Date().getTime() > contractDate.timestamp && contractDate.status < ContractDateStatus.PROCESSED;
            },
            selectContractCancel: function () {
                this.selectContractDialogVisible = false;
                this.selectedContract = null;
            },
            selectContractConfirm: function () {
                this.selectedContract = this.$refs['contract-selector'].selectedContract;
                console.log('selectContractConfirm  this.selectedContract:', this.selectedContract);
                // if (this.selectedContract == null) {
                //     this.metaMessage['error']('请选择合同');
                //     return false;
                // }
                if (this.selectedContract != null) {
                    this.contractDateAddOrUpdateData.contractTitle = this.selectedContract.title;
                    this.contractDateAddOrUpdateData.parties = this.selectedContract.otherParties ? JSON.stringify(this.selectedContract.otherParties) : "[]";
                    this.contractDateAddOrUpdateData.contractId = this.selectedContract.contractId || this.selectedContract.id;
                } else {
                    this.contractDateAddOrUpdateData.contractTitle = null;
                    this.contractDateAddOrUpdateData.parties = null;
                    this.contractDateAddOrUpdateData.contractId = null;
                }
                this.selectedContract = null;
                this.selectContractDialogVisible = false;
            },
            selectContractDialogClosed: function () {
                this.$refs['contract-selector'].clearContracts();
            },
            filterPartiesByTeamOwnKeyWord(partiesArray){
              let ownKeyWord;
              try {
                ownKeyWord = sessionStorage.getItem('teamInfo').ownKeyWord.split(',');
              }catch (e){
                ownKeyWord = [];
              }
              let keyWordReg = new RegExp(`(${ownKeyWord.join('|')})`,'g');
              let newArray = partiesArray.filter(item => {
                //如果名称包含团队关键字,就去除该名称
                if(ownKeyWord.length > 0 && item.search(keyWordReg) > -1){
                  return false;
                }
                return true;
              });
              return newArray;
            },
            getContractPartiesForTable: function (parties) {
                let partiesArray = this.filterPartiesByTeamOwnKeyWord(JSON.parse(parties));
                let showParty = partiesArray[0];
                if (showParty.length > 8 || partiesArray.length > 1) {
                    showParty = showParty.substring(0, 8) + "...";
                }

                return showParty;
            },
            getRelateMoneyForTable: function (relateMoney) {
                if (this.isNumber(relateMoney)) {
                    return "￥" + relateMoney;
                } else {
                    return relateMoney;
                }
            },
            isNumber: function (numberString) {
                if (parseFloat(numberString).toString() == "NaN") {
                    return false;
                } else {
                    return true;
                }
            },
            isContractStartOrEndEvent: function (contractDate) {
                return contractDate.event === '合同起始' || contractDate.event === '合同开始' || contractDate.event === '合同终止';
            },
            getAllTags(){
                getTags().then(res => {
                    this.allTags = res.data.data;
                    this.initTags(this.allTags);
                    for (const tag of res.data.data) {
                        if (tag.type === TagType.NEED_RESOLVE) {
                            this.allPendingTags.push(tag);
                        }
                    }
                }).catch(err => {
                    console.error("getAllTags  err:", err);
                });
            },
            initTags: function (allTags) {
                this.normalTagOptions = [];
                this.pendingTagOptions = [];
                for (const tag of allTags) {
                    if (tag.type === TagType.NEED_RESOLVE) {
                        this.pendingTagOptions.push(tag);
                    } else {
                        this.normalTagOptions.push(tag);
                    }
                }
            },
            clickTagsEdit(){
                this.tagEditMode = true;
                console.log('this.tagEditMode === this.$refs === ', this.$refs)
                this.$refs.tagComposition.api.changeMode();
            },
            handleTagCompositionModeChanged(mode, popperVisible){
                this.tagEditMode = popperVisible;
                console.log('handleTagCompositionModeChanged  mode:', mode);
                console.log('handleTagCompositionModeChanged  popperVisible:', popperVisible);
                if (mode === 1) {
                    this.tagCompositionInputPlaceholder = '搜索待办...';
                    this.tagCompositionInputClasses = '';
                } else {
                    this.tagCompositionInputPlaceholder = '请关联待办';
                    this.tagCompositionInputClasses = 'calendar-tag-input';
                }
            },
            handleTagCompositionTagResolved: function (tagData) {
                console.log('handleTagCompositionTagResolved  tagData:', tagData)
            },
            contractDateAddTag: function (name ,type) {
                return addTag({name: name, type: type});
                // if (this.contractDateAddOrUpdateData.id && this.contractDateAddOrUpdateData.id > 0) {
                //     return addContractDateTag(this.contractDateAddOrUpdateData.id, {name: name, type: type});
                // } else {
                //     return addTag({name: name, type: type});
                // }
            },
            contractDateDeleteTag: function (tagId) {
                // 不真实发请求，点击“保存”才和实体一起更新
                return Promise.resolve({
                    data:{code:0}
                });
                // if (this.contractDateAddOrUpdateData.id && this.contractDateAddOrUpdateData.id > 0) {
                //     return deleteContractDateTag(this.contractDateAddOrUpdateData.id, tagId);
                // } else {
                //     return Promise.resolve({
                //         data:{code:0}
                //     });
                // }
            },
            contractDateCompleteTag: function (tagId, data) {
                return completeContractDateTag(this.contractDateAddOrUpdateData.id, tagId, data);
            },
            contractDateCancelTagCompleteStatus: function (tagId) {
                return cancelCompleteContractDateTag(this.contractDateAddOrUpdateData.id, tagId);
            },
            contractDateCompleteTagForCheckbox: function (tagId, data) {
                completeContractDateTag(this.changeTagStatusData.id, tagId, data).then(res => {
                    this.changeTagStatusData.complete = true;
                    this.changeTagStatusData.status = ContractDateStatus.PROCESSED;
                    this.changeTagStatusData.tagDTOS[0].status = TagStatus.complete;
                    return Promise.resolve({
                        data:{code:0}
                    });
                }).catch(err => {
                    this.metaMessage['error']('完成待办失败');
                })
                // return completeContractDateTag(this.changeTagStatusData.id, tagId, data);
            },
            contractDateCancelTagCompleteStatusForCheckbox: function (tagId) {
                cancelCompleteContractDateTag(this.changeTagStatusData.id, tagId).then(res => {
                    this.changeTagStatusData.complete = false;
                    this.changeTagStatusData.status = ContractDateStatus.PENDING;
                    this.changeTagStatusData.tagDTOS[0].status = TagStatus.pending;
                    return Promise.resolve({
                        data:{code:0}
                    });
                }).catch(err => {
                    this.metaMessage['error']('取消完成失败');
                });
                // return cancelCompleteContractDateTag(this.changeTagStatusData.id, tagId);
            },
            contractDateEditDialogClose: function () {
                console.log('contractDateEditDialogClose ------------')
                this.tagCompositionShow = false;
                this.tagCompositionInputPlaceholder = '请关联待办';
                this.tagCompositionInputClasses = 'calendar-tag-input';
            },
            contractDateEditDialogOpened: function () {
                console.log('contractDateEditDialogOpened ------------')
                console.log('allPendingTags === ', this.allPendingTags);
                this.tagCompositionShow = true;
            }
        },
        beforeUnmount() {
            document.removeEventListener("click", this.bodyCloseMenus);
        },

    }
</script>

<style scoped>
    .right-panel {
        text-align: left;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        height: 100%;
        width: calc(100% - 283px);
    }

    .content-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: calc(100% - 122px);

    }

    .header-right {
        display: flex;
        align-items: center;
    }

    .content-header {
        display: flex;
        border-bottom: solid 1px #dddddd;
        padding: 7px 0;
    }

    .header-left {
        flex: 1;
    }

    .content-body {
        flex: 1;
        height: calc(100% - 46px);
        overflow-y: auto;
    }

    .header-container {
        /*padding: 20px 0;*/
    }

    .date-date {
        /*text-align: right;*/
        display: flex;
        justify-content: space-between;
        height: 22px;
        line-height: 22px;
        margin-bottom: 3px;
        position: relative;
    }

    .calendar-add-event-btn {
        display: none;
        position: absolute;
    }

    .el-calendar-day:hover .calendar-add-event-btn {
        display: block;
    }

    .calendar-date-text {
        position: absolute;
        right: 0;
        font-family: SourceHanSansSC;
        font-weight: 400;
        font-size: 14px;
        /*color: rgb(0, 0, 0);*/
        font-style: normal;
        letter-spacing: 0px;
        width: 24px;
        line-height: 24px;
        text-decoration: none;
        color: #868686;
    }

    .el-calendar-day:hover .calendar-date-text {
        background-color: var(--lightBlue);
        color: white;
        border-radius: 50%;
        text-align: center;
    }

    .date-event-more {
        height: 20px !important;
    }

    .events-popover-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 14px;
        line-height: 14px;
    }

    .event-edit-dialog-header {
        position: relative;
        flex-direction: column;
        width: 100%;
        padding-bottom: 16px;
        background-size: cover;
        flex: none;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
    }

    .tool-btns {
        display: flex;
        margin: 13px 16px 0 0;
        flex: none;
        align-self: flex-end;
        justify-content: space-between;
        align-items: center;
    }

    .btn-img {
        width: 16px;
        height: 16px;
    }

    .tool-btns .btn-img-wrapper:not(:last-child) {
        margin-right: 16px;
    }

    .btn-img-wrapper {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        cursor: pointer;
    }

    .btn-status-group {
        /*margin-right: 16px;*/
        /*color: #fff;*/
        height: 46px;
    }

    .summary {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 20px;
        padding: 5px 16px 0;
        margin: 0 0 10px;
        font-weight: 600;
        user-select: text;
        color: #fff;
        flex: none;
        align-self: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        box-sizing: border-box;
        word-break: break-word;
    }

    .summary-checkbox >>> .el-checkbox__input {
        height: 14px;

    }

    .summary-checkbox >>> .is-checked .el-checkbox__inner {
        background-color: #fff !important;

    }

    .summary-checkbox >>> .is-checked .el-checkbox__inner::after {
        border-color: var(--lightBlue) !important;

    }

    .summary-title {
        display: inline-block;
        margin-left: 10px;
    }

    .event-detail--time {
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        word-break: break-word;
        white-space: pre-wrap;
        user-select: text;
    }

    .eventDetail--entranceContainer {
        margin-top: 12px;
        display: flex;
        align-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
    }

    .event-edit-dialog-content {
        padding: 10px 0 8px;
        user-select: text;
        overflow-y: overlay;
        overflow-x: hidden;
        width: 100%;
        box-sizing: border-box;
        flex: auto;
    }

    .contract-date-read-dialog-row {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 5px 16px;
        flex: none;
        align-items: center;
        justify-content: space-between;
    }

    .contract-date-edit-dialog-row {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 30px;
        flex: none;
        align-items: center;
        justify-content: space-between;
    }

    .contract-date-read-dialog-row .icon {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 15px;
        line-height: 16px;
        font-size: 12px;
        flex: none;
        color: #8f959e;
        align-self: flex-start;
        cursor: default;
    }

    .contract-date-edit-dialog-row .icon {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 15px;
        line-height: 16px;
        font-size: 12px;
        flex: none;
        color: #8f959e;
        align-self: flex-start;
        cursor: default;
    }

    .operate-icon-wrapper {
        display: none;
        /*display: flex;*/
        justify-content: center;
        align-items: center;
        height: 46px;
        color: var(--el-table-font-color);
    }

    .el-table__row:hover .operate-icon-wrapper {
        display: flex;
    }

    .table-operate-icon {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 15px;
        line-height: 16px;
        font-size: 12px;
        flex: none;
        color: #8f959e;
        align-self: flex-start;
        cursor: pointer;
    }

    .events-popover-day {
        font-weight: bold;
        font-size: 18px;
        padding: 5px 0;
        color: #101010;
    }

    .event-week {
        color: #101010;
    }

    .event-month-day {
        font-family: SourceHanSansSC;
        font-weight: 400;
        font-size: 14px;
        color: rgb(64, 64, 64);
        font-style: normal;
        letter-spacing: 0px;
        line-height: 12px;
        text-decoration: none;
    }

    .detail-content {
        margin-right: 16px;
        font-size: 14px;
        white-space: pre-wrap;
        word-break: break-word;
        width: calc(100% - 55px);
        color: #000000;
        align-self: center;
        flex: auto;
    }

    .contract-date-edit-dialog-row-content {
        /*font-size: 12px;*/
        white-space: pre-wrap;
        word-break: break-word;
        width: calc(100% - 55px);
        color: #1f2329;
        align-self: center;
        flex: auto;
        text-align: left;
        padding-left: 9px;
    }

    .meta-dialog {
        position: fixed;
        z-index: 3;
        top: 15vh;
        left: 35%;
        width: 27.5%;
        text-align: left;
        background: #ffffff;
        border-radius: 4px;
        max-width: 400px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        overflow: hidden;
    }

    .meta-dialog .event-edit-dialog-header {
        padding: 0;
        /*background-image: linear-gradient(to right bottom, var(--lightBlue), rgb(235,251,227));*/
        background-color: var(--lightBlue);

        /*background-color: rgb(235,251,227);*/
    }

    .date-event-img {
        width: 16px;
        height: 16px;
        /*display: inline-block;*/
    }

    .contract-date-operate-column-read {
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 0;
        min-width: 200px;
    }

    .menu-item {
        padding: 10px;
        text-align: left;
        cursor: pointer;
        max-width: 200px;
    }

    .menu-item:hover {
        background: #e2e2e2;
    }

    .menu-item .el-checkbox {
        width: 100%;
        display: flex;
    }

    .menu-item >>> .el-checkbox__label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;
    }

    .addBtn {
        color: var(--lightBlue);
        cursor: pointer;
        height: 28px;
        font-size: 14px;
        line-height: 28px;
        margin-left: 20px;
        font-weight: 500;
    }

    .contract-title-read {
        cursor: pointer;
        text-decoration:underline;
    }

    .contract-title-edit {
        color: var(--lightBlue);
        cursor: pointer;
        padding: 0 20px;
    }

    .contract-title-edit-for-dialog {
        color: var(--lightBlue);
        cursor: pointer;
    }

    .contract-date-operate-column-edit {
        /*padding-left: 20px;*/
        /*display: flex;*/
        /*justify-content: space-between;*/
        display: flex;
        justify-content: space-between;
        padding: 0 10px 0 20px;
        min-width: 300px;
    }

    .edit-row-cancel-btn {
        width: 60px;
        text-align: center;
        background-color: white;
        border: 1px #dcdfe6 solid;
        border-radius: 4px;
        margin-left: 2px;
        cursor: pointer;
    }

    .edit-row-confirm-btn {
        width: 60px;
        text-align: center;
        background-color: #5280ff;
        color: white;
        border: 1px #dcdfe6 solid;
        border-radius: 4px !important;
        margin-left: 2px;
        cursor: pointer;
    }

    .contract-date-new-btns {
        /*display: inline-flex;*/
        /*width: 70px;*/
        /*justify-content: space-around;*/
        /*align-items: center;*/
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-btn {
        color: var(--lightBlue);
    }

    .expired {
        color: red;
    }


    .detail-icon-title {
        display: flex;
        justify-content: flex-start;
        width: 66px;
        min-width: 66px;
    }

    .detail-title {
        /*width: 60px;*/
    }

    .detail-title-required-span {
        font-family:SourceHanSansSC;
        font-weight:400;
        /*font-size:16px;*/
        color:rgba(255, 0, 0, 1);
        font-style:normal;
        letter-spacing:0px;
        /*line-height:24px;*/
        text-decoration:none;
        position: relative;
        top: -3px;
    }

    .period-time-checkbox {
        color: var(--font-gray);
        margin: 0 12px 0 15px;
    }

    .date-event {
        cursor: pointer;
        color: #000000;
        display: flex;
        flex-direction: row;
        margin-bottom: 2px;
        border-radius: 3px;
        /*font-weight: 700;*/
    }

    .date-event-prefix {
        width: 2px;
        background-color: var(--lightBlue);
        height: 39px;
    }

    .date-event-content {
        background-color: rgb(235, 243, 255);
        padding: 1px 2px 1px 3px;
        border-radius: 3px;
        width: calc(100% - 2px);
    }

    .date-event-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 1px;
        width: fit-content;
        max-width: 100%;
    }

    .events-popover-contents {
        margin-top: 10px;
    }

    .more-event-text {
        color: #A3A3A4;
    }

    .more-event-text-number {
        color: #404040;
    }

    .conclusive-firstArea{
        display: flex;
        align-items: flex-start;
    }
    .conclusive-firstArea-tags{
        text-align: left;
        display: flex;
        align-items: baseline;
        flex-shrink:1;
    }
    .conclusive-firstArea:hover .conclusive-firstArea-icon.show{
        opacity: 1;
        pointer-events: unset;
    }
    .conclusive-firstArea-icon{
        font-size:0;
        opacity: 0;
        cursor:pointer;
        pointer-events: none;
    }
    .conclusive-firstArea > span:not(.excluded),
    .conclusive-firstArea > div:not(.excluded){
        margin-top: 9px;
    }
    .conclusive-firstArea-questionnaire{
        width: 100px;
        flex-shrink:0;
    }
    .conclusive-label-icon{
        width: 18px;
        height: 18px;
        font-size: 18px;
        margin-right: 5px;
        flex-shrink:0;
    }
    .conclusive-label-text{
        width: 100px;
        line-height: 24px;
        text-align:left;
        flex-shrink:0;
    }
    .tag-inputTool-expanded{
        white-space: nowrap;
    }
    .conclusive-label-btn{
        padding: 0!important;
        color:var(--lightBlue);
        cursor:pointer;
    }

    .disable {
        cursor: unset;
        background-color: var(--gray-4);
    }

    .ctc-tag-wrapper {
        display: inline-block;
        margin-right: 10px;
    }

    .tags-container {
        height: 100%;
        display: flex;
        align-items: center;
    }

</style>
<style>

    .el-calendar-day {
        padding: 4px !important;
    }

    .more-events-popover {
        z-index: 2 !important;
        width: calc(14.28% - 40px) !important;
        min-width: calc(14.28% - 40px) !important;
    }

    #attach-select-ember19 .select-trigger {
        width: 110px !important;
    }

    .meta-dialog .el-radio__label {
        color: #fff !important;
    }

    .el-calendar-table .el-calendar-day {
        height: 142px;
    }

    .mode-change-container .el-radio-button__original-radio:checked + .el-radio-button__inner {
        background-color: white;
        color: var(--lightBlue);
    }

    .table-day-date-picker {
        position: fixed;
        margin: auto;
        width: fit-content;
        height: fit-content;
        inset: 0;
    }
    .parties .el-tag{
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .calendar-table {
        color: #101010;
        font-weight: 400;
    }

    .calendar-table .cell {
        padding-left: 20px;
        padding-right: 20px;
    }

    .calendar-table-header th {
        color: #101010 !important;
        font-weight: 400 !important;
    }

    .table-event-column {
        /*font-weight: 500 !important;*/
    }

    .table-parties-column {
        font-weight: 400 !important;
        color: #101010 !important;
    }

    .table-relate-money-column {
        font-weight: 400 !important;
    }

    .calendar-page-title {
        color: #101010 !important;
    }

    .row-read {
        /*height: 40px;*/
        /*line-height: 40px;*/
    }

    .row-edit {
        background-color: rgba(82, 128, 255, 0.1) !important;
    }

    .row-edit .cell {
        padding: 0 !important;
    }

    .row-edit input {
        border: none;
        border-radius: 0;
        height: 46px;
        background-color: rgba(82, 128, 255, 0);
    }

    .row-edit input:focus {
        background-color: rgba(82, 128, 255, 0.1);
    }

    .row-edit .el-input__icon {
        height: 46px;
    }

    .calendar-table td:first-child .cell, .calendar-table th:first-child .cell {
        padding-left: 21px;
    }

    .contract-table .el-table__body-wrapper {
        overflow-y: auto;
        max-height: 300px;
    }

    .el-calendar-table td.is-today .calendar-date-text {
        background-color: var(--lightBlue);
        color: white;
        border-radius: 50%;
        text-align: center;
        font-size: 16px;
    }

    .calendar-tag-input::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: var(--lightBlue);
    }
    .calendar-tag-input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: var(--lightBlue);
    }
    .calendar-tag-input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: var(--lightBlue);
    }

</style>
