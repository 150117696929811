<template>
    <div :class="getDateEventClass('date-event', '')">
        <div :class="getDateEventClass('date-event-prefix', '-prefix')"></div>
        <div :class="getDateEventClass('date-event-content', '')">
            <div :class="getDateEventClass('date-event-event', '-event')">
                <div class="date-event-text" v-html="contractDate.event"></div>
            </div>
            <div :class="getDateEventClass('date-event-contract-title', '-title')">
                <div class="date-event-text" v-html="contractDate.contractTitle"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import {ContractDateStatus} from "../../constant/contract"

    export default {
        name: "ContractDateEvent",
        props: ['contractDate'],
        components: {},
        data() {
            return {}
        },
        watch: {},
        mounted() {

        },
        unmounted() {
        },
        methods: {
            notExpiredAndProcessed: function () {
                return new Date().getTime() > this.contractDate.timestamp && this.contractDate.status === ContractDateStatus.PROCESSED;
            },
            expiredAndNotProcessed: function () {
                return new Date().getTime() > this.contractDate.timestamp && this.contractDate.status < ContractDateStatus.PROCESSED;
            },
            expiredAndProcessed: function () {
                return new Date().getTime() > this.contractDate.timestamp && this.contractDate.status < ContractDateStatus.PROCESSED;
            },
            getDateEventClass: function (initClassNames, suffix) {
                if (this.isContractStartOrEndEvent()) return initClassNames;
                let classNames = initClassNames;
                if (new Date().getTime() < this.contractDate.timestamp) {
                    // 未超期
                    if (this.contractDate.status === ContractDateStatus.PROCESSED) {
                        // 已完成
                        classNames += " processed-event" + suffix;
                    }
                } else {
                    // 已超期
                    if (this.contractDate.status === ContractDateStatus.PROCESSED) {
                        classNames += " expired-processed-event" + suffix;
                    } else {
                        classNames += " expired-not-processed-event" + suffix;
                    }
                }
                return classNames;
            },
            isContractStartOrEndEvent: function () {
                return this.contractDate.event === '合同起始' || this.contractDate.event === '合同开始' || this.contractDate.event === '合同终止';
            }
        },
        beforeUnmount() {
        },

    }
</script>

<style scoped>
    .date-event {
        background-color: #EBF3FF;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        margin-bottom: 1px;
        border-radius: 3px;
    }

    .processed-event {
        background-color: #F5F9FF;
    }

    .expired-not-processed-event {
        background-color: #FFEDEA;
    }

    .expired-processed-event {
        background-color: #F5F9FF;
    }

    .date-event-prefix {
        width: 2px;
        height: 39px;
        border-radius: 3px 0 0 3px;
        background-color: #5280FF;
    }

    .processed-event-prefix {
        background-color: #A9C0FF;
    }

    .expired-not-processed-event-prefix {
        background-color: #FF4E30;
    }

    .expired-processed-event-prefix {
        background-color: #A9C0FF;
    }

    .date-event-event {
        color: #2051CA;
    }

    .processed-event-event {
        color: #9BB1E8;
    }

    .expired-not-processed-event-event {
        color: #FF4E30;
    }

    .expired-processed-event-event {
        color: #9BB1E8;
    }

    .date-event-contract-title {
        color: #404040;
    }

    .processed-event-title {
       color: #A3A3A4;
    }

    .expired-not-processed-event-title {
        color: #404040;
    }

    .expired-processed-event-title {
        color: #A3A3A4;
    }

    .date-event-content {
        padding: 1px 2px 1px 3px;
        border-radius: 3px;
        width: calc(100% - 2px);
        box-sizing: border-box;
    }

    .date-event-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 1px;
        width: fit-content;
        max-width: 100%;
    }

</style>

